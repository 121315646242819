define("ember-cp-validations/-private/ember-internals", ["exports", "@ember/-internals/metal/index"], function (_exports, _index) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getDependentKeys = getDependentKeys;
  _exports.isDescriptor = isDescriptor;

  function getDependentKeys(descriptorOrDecorator) {
    if (_index.default && _index.default.descriptorForDecorator) {
      let descriptor = _index.default.descriptorForDecorator(descriptorOrDecorator);

      return descriptor._dependentKeys || [descriptor.altKey];
    } else {
      return descriptorOrDecorator._dependentKeys;
    }
  }

  function isDescriptor(o) {
    if (_index.default && _index.default.isClassicDecorator) {
      return _index.default.isClassicDecorator(o);
    } else {
      return o && (typeof o === 'object' || typeof o === 'function') && o.isDescriptor;
    }
  }
});